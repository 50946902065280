import * as functions from './functions';

function saveAsImage(renderer, cameras, scene, hash, orderLink) {

    let screenshotsHash
    if (hash !== '') {
        screenshotsHash = hash
    } else {
        screenshotsHash = functions.generateRandomHash(10)
    }

    const saveImagePromises = cameras.map((camera, index) => {
        return new Promise((resolve, reject) => {
            // Render the scene from the current camera's perspective
            renderer.render(scene, camera);

            const imgData = renderer.domElement.toDataURL("image/jpeg");

            Livewire.dispatch('saveImage', { base64Image: imgData, filename: `screenshot_${index}.jpg`, hash: screenshotsHash });

            resolve();
        });
    });

    Promise.all(saveImagePromises).then(() => {
        if (hash === '') {
            Livewire.dispatch('export-screenshot-pdf', { hash: screenshotsHash, orderLink: orderLink });
        }
    });

    // let imgData

    // try {
    //     let strMime = "image/jpeg";
    //     let strDownloadMime = "image/octet-stream";

    //     imgData = renderer.domElement.toDataURL(strMime);

    //     saveFile(imgData.replace(strMime, strDownloadMime), "Reponio_moje-konfigurace.jpg");

    // } catch (e) {
    //     console.log(e);
    //     return;
    // }

}

// let saveFile = function (strData, filename) {
//     let link = document.createElement('a');
//     if (typeof link.download === 'string') {
//         document.body.appendChild(link); //Firefox requires the link to be in the body
//         link.download = filename;
//         link.href = strData;
//         link.click();
//         document.body.removeChild(link); //remove the link when done
//     } else {
//         location.replace(uri);
//     }
// }

export { saveAsImage }
