//import { getConfigurationData } from "./app";
import { gsap } from "gsap";

const slider = document.querySelector('.presetItems');
let isDown = false;
let startX;
let scrollLeft;

slider.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
    cancelMomentumTracking();
});


slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
});


slider.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
    beginMomentumTracking();
});


slider.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * .75; //scroll-fast
    var prevScrollLeft = slider.scrollLeft;
    slider.scrollLeft = scrollLeft - walk;
    velX = slider.scrollLeft - prevScrollLeft;
});

// Momentum 

var velX = 0;
var momentumID;

slider.addEventListener('wheel', (e) => {
    // cancelMomentumTracking();
    // cancelMomentumTracking();
    const delta = Math.sign(e.deltaY); // Get the direction of the scroll
    const scrollAmount = delta * 50; // Adjust scroll speed as needed
    slider.scrollLeft += scrollAmount;
    velX = scrollAmount;
    beginMomentumTracking();
    e.preventDefault();
});

function beginMomentumTracking() {
    cancelMomentumTracking();
    momentumID = requestAnimationFrame(momentumLoop);
}
function cancelMomentumTracking() {
    cancelAnimationFrame(momentumID);
}
function momentumLoop() {
    slider.scrollLeft += velX;
    velX *= 0.95;
    if (Math.abs(velX) > 0.5) {
        momentumID = requestAnimationFrame(momentumLoop);
    }
}

$('.preset-filter > button').on('click', function (e) {
    e.preventDefault();
    let preset = $(this).data('preset')
    $('.preset-filter > button').removeClass('active')
    $(this).addClass('active')
    $('.presetItems > .item').removeClass('active')
    $('.presetItems > .item[data-category=' + preset + ']').addClass('active')
})

